.header1{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}
.header2{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: white !important;
}
.header3{
	width: 100%;
	animation: header3pc .45s ease-in-out;
	animation-fill-mode: forwards;
	transform-origin: center center;
	z-index: 99999999;
}

@keyframes header3pc{
	0% {
		//background-image: linear-gradient(to bottom right, #00174900, #03428200);
		background-color: transparent;
	}
	100% {
		//background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
		background-color: $colorPrimary;
	}
}

.headerindex {
	height: calc(100vh - 7.3rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(black, .5);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;//era block
			height: calc(100vh + 1px);
			min-height: 40rem;
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/bg_header1.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
		}

	}
	
}
.headerinterno {
	height: 5rem;
	z-index: 1;
}

#sub_menu_lancamentos, #sub_menu_vendas{
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 8.7rem;
	background-image: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	box-shadow: 7px 7px 10px rgba(black, .4);
	border-radius: 0 0 .5rem .5rem;
	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		& p{
			font-size: 1.1rem !important;
			font-weight: 400;
			background-image: none;
			padding: 1.2rem 1.5rem;
			margin: 0;
			border-radius: 0 0 .5rem .5rem;
			transition: all .25s;
			border-left: solid 3px transparent;
			&:hover{
				background-image: linear-gradient(to bottom left, transparent 30%, $colorSecondary);
				border-left: solid 3px white;
				padding-left: 2rem;
			}
		}
		& .titletipo{
			color: white;
			font-weight: 600;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 600;
			}
		}
	}
}

.tarja_top{
	width: 100%;
	display: block;
	height: 1.8rem;
	& .creci_header{
		color: $gray2;
		font-size: 1rem;
		font-weight: 400;
		width: 50%;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
	}
	& .itens_tarja{
		width: 50%;
		display: inline-block;
		& ul {
			flex: 0 0 100%;
			display: flex;
			align-items: center;
			height: 1.5rem;
			margin: 0%;
			padding: 0%;
			list-style: none;
			justify-content: flex-end;
			transition: all .25s ease;
			will-change: height;
			
			& .ith{
				display: inline-block;
				padding: 0 20px;

				& a{
					justify-content: space-between;
					color: $gray3;
					font-weight: 400;
					font-size: .9rem;
					&:hover{
						color: $colorPrimary;
					}
				}
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;

	& .superior {
		display: block;
		width: 100%;
		color: #ffffff;
		z-index: 3;
		
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 13rem;

			& img {
				display: block;
				width: 100%;
			}
		}

		/* GRUPO HEADER */

		$largura_share: 7;
		$largura_menu: 5;
		$largura_creci: 10;
		$largura_contact: 13;
		$diferenca: 13;
		& .share {
			display: inline-block;
			vertical-align: middle;
			width: #{$largura_share}rem;
			text-align: center;
			& .item{
				display: inline-block;
				vertical-align: middle;
				padding: 0.5rem;
				width: 1.5rem;
				& img{
					width: 100%;
				}
			}
		}

		& .creci {
			text-align: center;
			display: inline-block;
			width: #{$largura_creci}rem;
			vertical-align: middle;
			color: $gray1;
			font-size: 0.9rem;
		}
		& .menu {
			text-align: right;
			display: inline-block;
			width: #{$largura_menu}rem;
			vertical-align: middle;
			& .wpp_header{
				width: 100%;
				display: block;
				& ul{
					flex: 0 0 100%;
					display: flex;
					align-items: center;
					height: 70px;
					margin: 0%;
					padding: 0%;
					list-style: none;
					justify-content: flex-start;
					transition: all .25s ease;
					will-change: height;
					& .item_zap{
						display: inline-block;
						padding: 0 20px;
						& a{
							justify-content: space-between;
							color: $colorTerciary;
							font-weight: 600;
							font-size: 1.1rem;
							& p{
								font-size: 1.5rem;
								color: $colorPrimary;
								font-weight: 600;
							}
							& .p2{
								text-align: right;
								font-size: .9rem;
								color: darken($gray4, 10);
								font-weight: 400;
							}
							& img{
								width: 3rem;
								vertical-align: middle;
								align-items: left;
							}
						}
					}
				}
			}
		}

		& .sessions {
			display: inline-block;
			width: calc(100% - #{($largura_menu  + $diferenca)}rem);
			vertical-align: middle;
			& ul {
				flex: 0 0 0 100%;
				display: flex;
				align-items: center;
				height: 70px;
				margin: 0%;
				padding: 0%;
				list-style: none;
				justify-content: flex-end;
				transition: all .25s ease;
				will-change: height;
				& .itens_header{
					display: inline-block;
					padding: 0 20px;
					
					& a{
						justify-content: space-between;
						color: $colorTerciary;
						font-weight: 600;
						font-size: 1.1rem;
						&:hover{
							color: $colorPrimary;
						}
					}
				}
			}
		}

		& .contact{
			display: inline-block;
			width: #{$largura_contact}rem;
			vertical-align: middle;
			text-align: center;
			& .mg-tx{
				margin-bottom: 1rem !important;
				margin-top: 0.1rem !important;
			}
			& .mg-tx2{
				margin-bottom: 0 !important;
				margin-top: 0 !important;
			}
		}

	}



	& .frase {
		position: absolute;
		width: 50%;
		right: 5%;
		top: 32%;
		text-align: left;

		& span{
			line-height: 3rem !important;
			font-size: 2.3rem;
		}
		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}
}

.header-frases-arte{
	font-family: 'Quick Signature';
	color: lighten(white, 10%);
	text-shadow: 7px 2px 4px rgba(0, 0, 0, 1);
	font-size: 10rem;
	font-weight: 300;
	line-height: 30px;
}


.videoArea {
	z-index: 1;
	display: block;
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: $colorPrimary;
	background-image: url('/jnh/header/bg-header-01.jpg');
	background-size: cover;

/* 	& .videoareaareaoverlay {
		display: block;
		z-index: 3;
		background-color:rgba(black,0.5);
		position: absolute;
		width: 100%;
		height: 100%;
	}
	& .videoareaarea {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 1;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		& video {
			object-fit: cover;
			width: 100vw;
			z-index: 1;
			height: 100vh;
			display: inline-block;
			vertical-align: baseline;
		}
	} */

	& .container-header-pc{
		width: 100%;
		height: 100%;
	}
}
.container-bg-headerpc{
	display: block;
	position: absolute;
	top:0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.bg-headerpc{
	display: block;
	position: absolute;
	bottom: 0;
	height: 100vh;
	min-height: 40rem;
	width: 100%;
	will-change: background-image;
	background-image: url('/jnh/header/header.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	animation-name: bgheaderpc;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}

.header-mob{
	background-color: $colorSecondary;
	& .log{
		display: inline-block;
		vertical-align: middle;
		width: 80%;
		& .img{
			width: 10rem;
			display: inline-block;
			vertical-align: middle;
			& img{
				width: 100%;
			}
		}
	}
	& .btn-men{
		width: 20%;
		text-align: right;
		display: inline-block;
		vertical-align: middle;
	}
}
// @include keyframes('bgheaderpc'){
// 	0% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	30% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	40% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-02.jpg');
// 	}
// 	60% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-02.jpg');
// 	}
// 	70% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// 	100% {
// 		transform: scale(1.03);
// 		background-image: url('/jnh/header/bg-header-01.jpg');
// 	}
// }

.bg-headerinterno {
	background-color: darken($colorPrimary, 5);
}

.headermobile-btn{
	position: absolute;
	width: 100%;
	max-width: 280px;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	position: relative;
	background-color: $colorPrimary !important;
	will-change: background-image;
	background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center top;
	animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}
// @include keyframes('headermobile'){
// 	0% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	30% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	40% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
// 	}
// 	60% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
// 	}
// 	70% {
// 		transform: scale(1);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// 	100% {
// 		transform: scale(1.02);
// 		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
// 	}
// }

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.headerpc{
		& .superior {
			& .logo {
				width: 11.6rem;
	
				& img {
					display: block;
					width: 100%;
				}
			}
		}
	}

	.headerindex{
		height: calc(100vh - 6.9rem);
	}
}

@media all and (max-width: 1023px){
	.headerindex{
		background-color: white;
		height: calc(100vh - 5.7rem);
		margin-bottom: 0;
	}
	.headerpc{
		
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}

/* @media all and (max-width: 600px) and (min-width: 480px){
	.videoArea{
		display: block;
		width: 100%;
		height: calc(54rem) !important;
		min-height: calc(54rem) !important;
		position: relative;
		/* background-color: #04111A !important;
		background-color: $colorPrimary !important;
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		
	}
} */
@media all and (max-width: 1204px){
	.headerpc{
	
		& .superior{
			& .sessions{
				& .itemsession{
					& a{
						line-height: 1.2rem !important;
					}
				}
			}
		}

	}
}
