.bg_busca_interna{
	background-color: white;
	border-bottom: solid 2px $gray5;
}

.busca-filtros{
	display: block;
	width: 100%;
	background-color: white;
	padding: 1rem;
	border: 1px solid $gray1;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}

.menuacao_btn{
	background-color: $colorPrimary !important;
	border: solid 1px $colorPrimary !important;
	color: white !important;
}

.busca {
	background-color: white;
	display: block;
	width: 65%;
	padding: 2rem;
	text-align: center;
	border: solid 1px $gray4;
	box-shadow: 0 0 10px 1px rgba(black, .1);
	border-radius: .2rem;
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -45%);

	& .btns_tipo_busca{
		display: block;
		width: 100%;
		& .botoes{
			display: flex;
			justify-content: center;
			align-items: center;

			& button{
				background-color: white;
				border: solid 1px $colorTerciary;
				border-radius: 2rem;
				font-size: 1.6rem;
				color: $colorTerciary;
				font-weight: 600;
				padding: .5rem 1.5rem;
				margin: 0 .5rem;
				cursor: pointer;
				transition: all .15s;
			}
		}
	}

	& .busca_cond{
		width: 100%;
		display: block;
		& .btns_Cond{
			width: 100%;
			display: block;
			margin: auto;

			& a{
				& p{
					text-align: center;
					font-size: 1.3rem;
					font-weight: 400;
					border: solid 1px $gray4;
					border-radius: .2rem;
					padding: 1rem .5rem;
					margin: 0;
				}
				& .p2{
					background-color: $colorPrimary;
					color: white;
					text-align: center;
					font-size: 1.3rem;
					font-weight: 400;
					border-radius: .2rem;
					padding: 1rem .5rem;
					margin: 0;
				}
			}
		}
	}

	& .busca-filtro{
		width: 100%;
		display: block;
		& .bloco_busca{
			padding: 0;
			margin: 0;
			text-align: center;
			display: block;
			width: 100%;
			& .filtros_busca_home{
				width: 30%;
				display: inline-block;
				padding: 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}
			& .filtros_busca_home2{
				width: 30%;
				display: inline-block;
				padding: 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}
			& .filtros_busca_home_input{
				width: 20%;
				display: inline-block;
				padding: 0;
			}
			& .btn-home{
				padding: .8rem .5rem;
				border-radius: .2rem;
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				background-color: $colorPrimary;
				color: white;
				font-size: 1rem;
				font-weight: 600;
				border: none;
				cursor: pointer;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
				
			}
		}
	}
}

.pd_btns_busca_mobile{
	padding: 0 .1rem;
}

.btn_mostrar_mais_busca_interna{
	text-align: center;
	width: 10%;
	background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	color: white;
	font-size: 1rem;
	font-weight: 600;
	border-radius: 2rem;
	border: solid 2px white;
	padding: .5rem .8rem;
	box-shadow: 5px 7px 15px rgba(black, .3);
	cursor: pointer;
	transition: all .25s ease-out;
	&:hover{
		background: linear-gradient(to bottom right, $colorSecondary, $colorPrimary);
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .6rem 0;
	line-height: 1.5rem;
	border: none;
	border-radius: .1rem;
	margin-top: 2px;
	margin-right: 3px;
	background-color: $colorPrimary;
	color: white;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}
.botao_limpar_int{
	text-align: center;
	padding: .55rem 0;
	line-height: 1.5rem;
	border: solid 1px $colorTerciary;
	border-radius: .1rem;
	margin-top: 2px;
	margin-right: 3px;
	background-color: $colorTerciary;
	color: white;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	&:hover{
		background-color: darken($colorTerciary, 5);
	}
}


// SETA DA PAGINAÇAO

@-webkit-keyframes uparrow {
	0% { -webkit-transform: translateY(0); opacity: 0.4 }
	100% { -webkit-transform: translateY(-0.4em); opacity: 0.9 }
  }
@-webkit-keyframes downarrow {
0% { -webkit-transform: translateY(0); opacity: 0.4 }
100% { -webkit-transform: translateY(0.4em); opacity: 0.9 }
}

.arrow {
	border-color:transparent;
	border-style:solid;
	border-width:0 2em;
	display:block;
	height:0;
	margin-top: 2rem;
	opacity:0.4;
	text-indent:-9999px;
	transform-origin: 50% 50%;
	width:0;
}
.up {
	-webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
	border-bottom:2em solid $colorPrimary;
}
.down {
	-webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
	border-top:2em solid $colorPrimary;
}


.height-but{
	height: 3rem !important;
}

.bg-person{
	background-color: $colorPrimary;
}

@media all and (max-width: 1023px){

	.busca {
		background-color: white;
		display: block;
		width: 90%;
		padding: 1.5rem;
		text-align: center;
		border: solid 1px $gray4;
		box-shadow: 0 0 10px 1px rgba(black, .1);
		border-radius: .2rem;
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -45%);

		& .btns_tipo_busca{
			display: block;
			width: 100%;

			& .botoes_mob{
				display: block;
				width: 100%;
				
				& .bloco_busca{
					display: inline-block;
					vertical-align: middle;
					width: 50%;

					& button{
						background-color: white;
						border: solid 1px rgba($colorTerciary, .5);
						border-radius: 2rem;
						font-size: 1rem;
						color: $colorTerciary;
						font-weight: 600;
						padding: .5rem 0;
						text-align: center;
						margin: 0 .5rem;
						cursor: pointer;
						transition: all .15s;
						width: 100%;
					}
				}
			}
		}

		& .busca_cond{
			width: 100%;
			display: block;
			& .btns_Cond{
				width: 100%;
				display: block;
				margin: auto;
				& a{
					& p{
						text-align: center;
						font-size: 1.3rem;
						font-weight: 400;
						border: solid 1px $gray4;
						border-radius: .2rem;
						padding: 1rem .5rem;
						margin: 0;
					}
					& .p2{
						background-color: $colorPrimary;
						color: white;
						text-align: center;
						font-size: 1.3rem;
						font-weight: 400;
						border-radius: .2rem;
						padding: 1rem .5rem;
						margin: 0;
					}
				}
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				& .filtros_busca_home{
					width: 100%;
					display: inline-block;
					padding: 0 0 1rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home2{
					width: 100%;
					display: inline-block;
					padding: 0 0 2rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home_input{
					width: 50%;
					display: inline-block;
					padding: 0;
				}
				& .btn-home{
					padding: .8rem .5rem;
					border-radius: .2rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorPrimary;
					color: white;
					font-size: 1rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
					
				}
			}
		}
	}
	
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		padding-bottom: 7rem;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
}
.heigt{
	height: 2.3rem !important;
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}
