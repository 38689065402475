.galeriaa_left{

}

.galeriaa_right{
	padding: .5rem 1rem;
	width: 100%;
	border-left: solid 2px $gray4;
	& .tarja_valor{
		font-size: 2rem;
		color: $colorPrimary;
		font-weight: 600;
	}
}

.formulario_interno{
	background-color: white;
	padding: .5rem 1.5rem;
	border-radius: .2rem;
	border: solid 1px $gray4;

	& h4{
		font-size: 1.5rem;
		font-weight: 600;
		color: $colorPrimary;
	}

	& .btn_zap_interno{
		width: 80%;
		display: block;
		background-color: #25d366;
		margin: auto;
		border-radius: 2rem;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		-webkit-animation: pulse 1.5s infinite;
		& p{
			text-align: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 600;
			padding: .5rem .2rem;
			vertical-align: middle;
			
			& i{
				vertical-align: middle;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}
		& .pulse-button:hover {
			-webkit-animation: none;
		}


		@-webkit-keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 20px rgba(#25d366, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#25d366, 0);
			}
		}
	}
	
	& .corretor_img{
		display: inline-block;
		vertical-align: middle;
		width: 30%;
		& .img{
			width: 6rem;
			& img{
				width: 100%;
				border-radius: 50%;
			}
		}
	}

	& .corretor_dados{
		display: inline-block;
		vertical-align: middle;
		width: 70%;
		padding: 0 0 0 .5rem;
		& p{
			margin: .2rem 0;
			font-size: .8rem;
			font-weight: 500;
			color: $gray2;
		}

		& .nome{
			margin: .2rem 0;
			font-size: .9rem;
			font-weight: 600;
			color: $gray2;
		}

		& .btn_zap{
			display: block;
			width: 80%;
			margin-left: 0;
			background-color: #25d366;
			border-radius: 2rem;
			& a{
				& p{
					text-align: center;
					color: white;
					font-weight: 500;
					font-size: .9rem;
					padding: .3rem 0;
				}
			}

			&:hover{
				background-color: darken(#25d366, 5);
			}
		}
	}

	& .politica_formularios{
		width: 100%;
		display: block;
		
		& p{
			text-align: left;
			color: $gray2;
			font-weight: 400;
			font-size: .9rem;
			user-select: none;
			& a{
				color: $colorPrimary;
				font-weight: 500;
				font-size: .9rem;
				user-select: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}

		& label{
			text-align: left;
			color: $gray2;
			font-weight: 400;
			font-size: .9rem;
			user-select: none;
		}
	}
	
}
.texto_descricao_imo{
	max-width: 55rem;
	margin-left: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	color: $gray1;
}

.width_pc_mobile{
	width: 55% !important;
}

.fav_button{
	text-align: center;
	margin: 0;
	width: 40%;
	display: inline-block;
	vertical-align: middle;
	background-color: white;
	border-radius: .2rem 0 0 .2rem;
	border: solid 1px $gray4;
	padding: .15rem 0;
	& p{
		margin: 0;
		text-align: left;
		vertical-align: middle;
		color: $gray3 !important;
		font-size: 1rem;
		font-weight: 500;
	}
	
}

.share_session{
	background-color: white;
	border-radius: 0 .2rem .2rem 0;
	border-top: solid 1px $gray4;
	border-right: solid 1px $gray4;
	border-bottom: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	width: 60%;
	padding: 0.15rem 0;
	& .item2{
		& p{
			padding: 0;
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: 1rem;
			font-weight: 600;
		}
	}
	& .item{
		display: inline-block;
		text-align: center;
		width: 20%;
		//padding: 0 .5rem;
	}
}

.imprimir_session{
	background-color: white;
	border: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	border-radius: .2rem 0 0 .2rem;
	width: 40%;
	padding: .15rem 0;
	& .item{
		display: inline-block;
		text-align: center;
		width: 55%;
		& p{
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: 1rem;
			font-weight: 600;
		}
	}
	& .item2{
		display: inline-block;
		text-align: center;
		width: 45%;
	}
}
.avaliar_session{
	width: 60%;
	vertical-align: middle;
	display: inline-block;
	border-radius: 0 .2rem .2rem 0;
	border-top: solid 1px $gray4;
	border-right: solid 1px $gray4;
	border-bottom: solid 1px $gray4;
	& .item{
		background-color: white;
		width: 100%;
		border-radius: 0 .2rem .2rem 0 ;
		
		& p{
			margin: 0;
			color: $gray3;
			text-align: center;
			font-size: 1.2rem;
		}
	}
	& .b_avaliar_imo{
		background-color: white;
		border: solid 2px transparent;
		color: $colorPrimary;
		padding: 0 .5rem;
		cursor: pointer;
		&:hover, &:active, &:visited{
			background-color: $colorSecondary;

		}
	}
}

/* 1200px */
@media all and (min-width: 1024px) and (max-width: 1380px){
	.fav_button{
		text-align: center;
		margin: 0;
		width: 40%;
		display: inline-block;
		vertical-align: middle;
		background-color: white;
		border-radius: .2rem 0 0 .2rem;
		border: solid 1px $gray4;
		padding: .18rem 0;
		& p{
			margin: 0;
			text-align: left;
			vertical-align: middle;
			color: $gray3 !important;
			font-size: 1rem;
			font-weight: 500;
		}
		
	}
	
	.imprimir_session{
		background-color: white;
		border: solid 1px $gray4;
		display: inline-block;
		vertical-align: middle;
		border-radius: .2rem 0 0 .2rem;
		width: 40%;
		padding: .1rem 0;
		& .item{
			display: inline-block;
			text-align: center;
			width: 55%;
			& p{
				margin-left: 0.7rem;
				margin-right: 0.7rem;
				display: inline-block;
				color: $gray3;
				font-size: 1rem;
				font-weight: 600;
			}
		}
		& .item2{
			display: inline-block;
			text-align: center;
			width: 45%;
		}
	}
}



/* MOBILE */
@media all and (max-width: 1023px){
	.fav_button{
		text-align: center;
		margin: auto;
		width: 100%;
		background-color: white;
		border-radius: .2rem;
		border: solid 1px $gray4;
		padding: 0;
		& p{
			text-align: right;
			vertical-align: middle;
			padding: 0 1rem;
			color: $gray3 !important;
			font-size: 1rem;
			font-weight: 500;
		}
	}

	.galeriaa_right{
		padding: .5rem 1rem;
		width: 100%;
		border-left: solid 2px $gray4;
		& .tarja_valor{
			font-size: 2rem;
			color: $colorPrimary;
			font-weight: 600;
		}
	}

	.formulario_interno{
		background-color: white;
		padding: .5rem 1.5rem;
		border-radius: .2rem;
		border: solid 1px $gray4;
	
		& h4{
			font-size: 1.5rem;
			font-weight: 600;
			color: $colorPrimary;
		}
		
	}
	.texto_descricao_imo{
		width: 100%;
		line-height: 1.3rem;
		font-size: 1rem;
	}

	.width_pc_mobile{
		width: 100% !important;
	}

	.share_session{
		background-color: white;
		border-radius: 0 .2rem .2rem 0;
		border: solid 1px $gray4;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		padding: 0.15rem 0;
		& .item2{
			& p{
				padding: 0;
				margin-left: 0.7rem;
				margin-right: 0.7rem;
				display: inline-block;
				color: $gray3;
				font-size: 1rem;
				font-weight: 600;
			}
		}
		& .item{
			display: inline-block;
			text-align: center;
			width: 20%;
		}
	}
	
	.avaliar_session{
		width: 100%;
		vertical-align: middle;
		display: inline-block;
		border-radius: 0 .2rem .2rem 0;
		border: solid 1px $gray4;
		& .item{
			background-color: white;
			width: 100%;
			border-radius: 0 .2rem .2rem 0 ;
			
			& p{
				margin: 0;
				color: $gray3;
				text-align: center;
				font-size: 1.2rem;
			}
		}
		& .b_avaliar_imo{
			background-color: white;
			border: solid 2px transparent;
			color: $colorPrimary;
			padding: 0 .5rem;
			cursor: pointer;
		}
	}

}