.bg-index-pat {
	display: block;
	width: 100%;
	position: relative;
	height: 500px;
	overflow: hidden;

	& .bg-index {
		background-image: url('/jnh/banner01.jpg');
		background-color: $gray4;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: absolute;

		height: 100%;
		width: 100%;

		@include animation-duration(30s);
		@include animation-name(bg_index);
		@include animation-iteration-count(infinite);
		@include animation-direction(alternate);
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;
	}
}
.secao_categ{
	display: block;
	width: 100%;
	& .title_secao_categ{
		text-align: center;
		& h1{
			font-size: 2.3rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	& .trindade_categ{
		display: block;
		width: 100%;

		& .bloco{
			width: 100%;
			height: 15rem;
			display: block;
			@include border-box;
			padding: 1rem;
			position: relative;
			overflow: hidden;
			border-radius: .2rem;

			& .fade{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background: linear-gradient(to top, rgba(black, .6), rgba(black, .1));
			}

			& img{
				width: 100%;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: all .3s;
			}

			& p{
				font-size: 1.8rem;
				font-weight: 600;
				color: white;
				position: absolute;
				bottom: 1rem;
				left: 1.5rem;
				z-index: 9999;
			}

			&:hover{
				& img{
					transform: translate(-50%, -50%) scale(1.1);
				}
				& .fade{
					background: linear-gradient(to top, rgba(black, .4), rgba(black, 0));
				}
			}
		}
	}
}

.title_condomi{
	text-align: center;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
	}
	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray3;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.credipronto_section{
	display: block;
	width: 100%;
	background-color: rgba(#EBF6FF, .9);
	border-radius: .2rem;
	& .texto{
		display: inline-block;
		width: 50%;
		vertical-align: middle;
		& h2{
			padding: 0 0 0 3rem;
			color: $colorTerciary;
			font-size: 2rem;
			font-weight: 600;
			z-index: 100;
			line-height: 4.5rem;
		}
		& p{
			padding: 0 0 0 3rem;
			font-size: 1.3rem;
			font-weight: 500;
			color: $gray3;
		}
		& a{
			& p{
				display: block;
				width: 45%;
				height: 4rem;
				background-color: #003563;
				color: white;
				font-size: 1.3rem;
				font-weight: 600;
				padding: 1rem 1rem;
				border-radius: .2rem;
				text-align: center;
			}
		}
	}

	& .bgimg{
		display: inline-block;
		width: 50%;
		vertical-align: middle;
		z-index: -5;

		& .img{
			width: 100%;
			& img{
				width: 100%;
			}
		}
	}
}

.blog_section{
	width: 100%;
	display: block;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: 0;
		background-color: $colorPrimary;
	}
}
.bg_section_contatos{
	background-color: $colorTerciary;
	display: block;
	width: 100%;
	& .section_servicos{
		width: 100%;
		display: block;
		background-color: transparent;
		text-align: center;

		& h2{
			font-size: 1.75rem;
			font-weight: 600;
			color: white;
		}

		& a{
			display: inline-block;
			width: 100%;
			vertical-align: top;
			border-radius: .2rem;
			transition: all .15s;
			border: dashed 2px transparent;

			& .bloco_servico{
				width: 100%;
				display: block;
				background-color: white;
				border-radius: .5rem;
				text-align: center;
				padding: 2rem 0;
				box-shadow: 0 0 20px 0 rgba(black, .4);
				& .img{
					width: 3rem;
					display: block;
					& img{
						transition: all .15s;
						text-align: center;
						width: 100%;
					}
				}

				& h2{
					color: $colorTerciary;
					font-size: 1.1rem;
					font-weight: 600;
					text-align: center;
				}

				& p{
					padding: 0;
					color: $colorPrimary;
					font-size: .9rem;
					font-weight: 400;
					text-align: center;	
					transition: all .15s;
				}
			}


			&:hover{
				& .img{
					& img{
						transform: scale(1.1);
					}
				}
			}
		}
	}
}


.page-wrap {
	display: -webkit-box;           /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;              /* OLD - Firefox 19- (doesn't work very well) */
	display: -ms-flexbox;           /* TWEENER - IE 10 */
	display: -webkit-flex;          /* NEW - Chrome */
	display: flex;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}



.main-content {
	-webkit-box-ordinal-group: 2;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 2;      /* OLD - Firefox 19- */
	-ms-flex-order: 2;              /* TWEENER - IE 10 */
	-webkit-order: 2;               /* NEW - Chrome */
	order: 2;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	width: 50%;                     /* No flex here, other cols take up remaining space */
	-moz-box-flex: 1;               /* Without this, Firefox 19- expands to widest paragraph, overrides width */
	background: $colorQuarterly;
}

.main-content2 {
	-webkit-box-ordinal-group: 2;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 2;      /* OLD - Firefox 19- */
	-ms-flex-order: 2;              /* TWEENER - IE 10 */
	-webkit-order: 2;               /* NEW - Chrome */
	order: 2;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	width: 55%;                     /* No flex here, other cols take up remaining space */
	-moz-box-flex: 1;               /* Without this, Firefox 19- expands to widest paragraph, overrides width */
	background: $gray5;
}

.home-text{
    font-size: 1.1rem;
}

.main-nav {
	-webkit-box-ordinal-group: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 1;      /* OLD - Firefox 19- */
	-ms-flex-order: 1;              /* TWEENER - IE 10 */
	-webkit-order: 1;               /* NEW - Chrome */
	order: 1;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	-webkit-box-flex: 1;            /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;               /* OLD - Firefox 19- */
	width: 20%;                     /* For old syntax, otherwise collapses. */
	-webkit-flex: 1;                /* Chrome */
	-ms-flex: 1;                    /* IE 10 */
	flex: 1;                        /* NEW, Spec - Opera 12.1, Firefox 20+ */
	background: $gray2;
	width: 100%;
	z-index: 2;
	background-image: url('/jnh/background/condominio.jpg');
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}

#ux_to_top{
	position: relative;
	top: -90px;
	left: -58%;
	z-index: 999999;
}

.main-nav2 {
	-webkit-box-ordinal-group: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-ordinal-group: 1;      /* OLD - Firefox 19- */
	-ms-flex-order: 1;              /* TWEENER - IE 10 */
	-webkit-order: 1;               /* NEW - Chrome */
	order: 1;                       /* NEW, Spec - Opera 12.1, Firefox 20+ */
	-webkit-box-flex: 1;            /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: 1;               /* OLD - Firefox 19- */
	-webkit-flex: 1;                /* Chrome */
	-ms-flex: 1;                    /* IE 10 */
	flex: 1;                        /* NEW, Spec - Opera 12.1, Firefox 20+ */
	background: white;
	z-index: 2;
	background-image: url('/jnh/background/imglogo.png'), linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.btn_cleanfilter{
	border-radius: .1rem !important;
	border: solid 1px $colorTerciary!important;
}

.btn_cleanfilter2{
	border-radius: .1rem !important;
	border: none;
}

.sessao_sobre_imob{
	background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
	display: table;
	width: 100%;

	& .descricao_sobre{
		width: 100%;
		& img{
			margin: auto;
		}
		.text_nav2{
			font-size: 1.5rem;
			line-height: 2.5rem;
			color: white;
			font-weight: 600;
		}
		.text_nav3{
			& p{
				font-size: 1.2rem;
				line-height: 1.8rem;
				padding-bottom: 1rem;
				color: white;
				text-align: center;
			}
			& .p2{
				text-align: center;
				font-size: .8rem;
				color: white;
				font-weight: 600;
			}
			& a{
				cursor: pointer;
				color: white;
				text-align: center;
				vertical-align: middle;
				box-shadow: 5px 5px 10px rgba(black, .3);
				background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
				padding: .5rem 1rem;
				border-radius: 1rem;
				&:hover{
					background-image: linear-gradient(to top left, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
				}
			}
		}
	}
}

@include keyframes(bg_index){
	from {
		@include scale(1);
	}
	to {
		@include scale(1.2);
	}
}

.servicos {
	display: block;
	width: 100%;
	background-color: lighten($colorSecondary, 5);
	padding: 5rem 1.5rem;
	@include border-box;

	& p {
		color: #ffffff;
	}
	
	& .servicos-img {
		display: block;
		width: 5rem;
		user-select: none;
		
		& img {
			display: block;
			width: 100%;
		}
	}
}

.shadow-box{
	// @include filter('drop-shadow(0 2px 6px rgba(0,0,0,.3))');
	@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
}

$teste: '0 0 20px 5px #{rgba($colorSecondary, .30)}';
.box-item{
 
	@include box-shadow($teste);
	width: 60%;
	border-radius: 9%;
	text-align: center;
	padding: 1.5rem 1rem;
	min-height: 13rem;
	&:hover{
		background-color: white;
		& img{
			transform: scale(1.2);
		}
	}
	& img{
		width: 6rem;
		transition: .15s all;
	}

	& span{
		color: $gray2;
		font-size: 1.3rem;
		font-weight: 500;
		line-height: 1.3em;
	}
}

.bganuncie{
	background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	background-size: cover;
	background-position: center;
	height: 30rem;
	filter: brightness(100%);
	position: relative;
	border-right: solid 1px $colorPrimary;
	&:hover{
		filter: brightness(110%);
	}
}

.bgencomende{
	background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	background-size: cover;
	background-position: center;
	filter: brightness(100%);
	height: 30rem;
	position: relative;
	&:hover{
		filter: brightness(110%);
	}
}



.textanuncie{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center !important;
	& h3{
		color: white;
		font-size: 2.2rem;
		font-weight: 600
	}

	& p{
		font-size: 1.2rem;
		color: white;
		line-height: 1.5em;
		font-weight: 500;
		text-align: center !important;
	}
	.buttonanuncie{
		border-radius: 2rem;
		text-transform: initial !important;
		font-size: 1.1rem;
		font-weight: 600;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		padding: .5rem 1rem;
		box-shadow: 5px 5px 10px rgba(black, .2);
		&:hover{
			background: linear-gradient(to bottom right, $colorSecondary, $colorPrimary);;
		}
	}
}

.miniatura_infraestrutura{
	background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	@include border-box;
	padding: 2rem .5rem;
	border-radius: 2rem;
	min-height: 20rem;
	vertical-align: middle;
	position: relative;
	box-shadow: 0 0 20px 0 rgba(black, .3);
	& img{
		margin: auto;
		display: block;
		width: 50%;
	}
	& .infra_nome{
		position: absolute;
		bottom: 2rem;
		left: 0;
		width: 100%;
	}
	& p{
		color: white;
		font-size: 1.1rem;
		font-weight: 500;
		width: 100%;
		text-align: center;
	}
	&:hover{
		background: linear-gradient(to bottom right, $colorSecondary, $colorPrimary);
	}
}
.box_header{
	border-bottom: solid 2px $gray5;
	z-index: 999;
	position: relative;
}

.botao_voltar{
	background-color: $colorPrimary;
	border: none;
	border-radius: .2rem;
	padding: .3rem 1rem;
	color: white !important;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
}


@media all and (max-width: 1023px){

	.secao_categ{
		display: block;
		width: 100%;
		& .title_secao_categ{
			text-align: center;
			& h1{
				font-size: 2rem;
				font-weight: 600;
				color: $colorTerciary;
			}
			& p{
				font-size: 1rem;
				font-weight: 400;
				color: $gray3;
				text-align: center;
			}
			& .under_p{
				text-align: center;
				width: 4.5rem;
				height: 4px;
				margin: auto;
				background-color: $colorPrimary;
			}
		}

		& .trindade_categ{
			display: block;
			width: 100%;

			& .bloco{
				width: 100%;
				height: 12.5rem;
				display: block;
				@include border-box;
				padding: 1rem;
				position: relative;
				overflow: hidden;
				border-radius: .2rem;

				& .fade{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 999;
					background: linear-gradient(to top, rgba(black, .6), rgba(black, .1));
				}

				& img{
					width: 100%;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 99;
					transform: translate(-50%, -50%);
					transition: all .3s;
				}

				& p{
					font-size: 1.8rem;
					font-weight: 600;
					color: white;
					position: absolute;
					bottom: 1rem;
					left: 1.5rem;
					z-index: 9999;
				}

				&:hover{
					& img{
						transform: translate(-50%, -50%) scale(1.1);
					}
					& .fade{
						background: linear-gradient(to top, rgba(black, .4), rgba(black, 0));
					}
				}
			}
		}
	}

	.title_condomi{
		text-align: center;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.credipronto_section{
		display: block;
		width: 100%;
		background-color: rgba(#EBF6FF, .9);
		padding: 0 1rem;
		border-radius: .2rem;
		& .texto{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& h2{
				padding: 1rem;
				color: $colorTerciary;
				font-size: 1.7rem;
				font-weight: 600;
				z-index: 100;
				line-height: 2.5rem;
			}
			& p{
				padding: 1rem;
				font-size: 1.1rem;
				font-weight: 500;
				color: $gray3;
			}
			& a{
				& p{
					display: block;
					width: 100%;
					height: 4rem;
					background-color: #003563;
					color: white;
					font-size: 1.3rem;
					font-weight: 600;
					padding: 1rem 1rem;
					border-radius: .2rem;
					text-align: center;
				}
			}
		}
	}

	.blog_section{
		width: 100%;
		display: block;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: 0;
			background-color: $colorPrimary;
		}
	}

	.section_servicos{
		width: 100%;
		display: block;
		background-color: lighten($gray5, 4);
		& .bloco_servico{
			width: 100%;
			display: block;
			vertical-align: middle;
			& .img{
				width: 2.5rem;
				margin: auto;
				& img{
					width: 100%;
				}
			}
			& h2{
				color: $colorPrimary;
				font-size: 1.2rem;
				font-weight: 600;
			}
			& p{
				padding: 0;
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
			}
		}
	}

	.bganuncie{
		background-color: $colorQuarterly;
		background-size: cover;
		background-position: center;
		height: 25rem;
		filter: brightness(100%);
		position: relative;
		border: none;
		border-bottom: solid 1px $colorPrimary;
		&:hover{
			filter: brightness(110%);
		}
	}

	.bgencomende{
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		background-size: cover;
		background-position: center;
		filter: brightness(100%);
		height: 25rem;
		position: relative;
		&:hover{
			filter: brightness(110%);
		}
	}

	.box-item{
		@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
		width: 70%;
		border-radius: 9%;
		text-align: center;
		padding: 1.5rem 1rem;
		min-height: 13rem;
		&:hover{
			& img{
				transition: none;
				transform: none;
			}
		}
	
		& span{
			color: $gray2;
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 1.3em;
		}
	}
	/* .buttonanuncie{
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		bottom: 6%;
	} */

	.textanuncie{
		position: initial;
		transform: none;
	}
	/* .bganuncie, .bgencomende{
		height: 24rem;
	} */
}
.input-novidades:-webkit-autofill,
.input-novidades:-webkit-autofill:hover, 
.input-novidades:-webkit-autofill:focus, 
.input-novidades:-webkit-autofill:active  {
    -webkit-box-shadow:0 0 0 50px rgba(0,0,0,0) inset !important;
    -webkit-text-fill-color: white !important;
}

@media all and (min-width: 320px) and (max-width: 1023px){
	.max-sz-con{
		max-width: 40rem !important;
		padding-left: 2rem !important;
		padding-right: 1rem !important;
	}
}

@media all and (max-width: 1023px){
	.page-wrap{
		display: block;
	}
	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}
	#ux_to_top{
		left: -14%;
	}
}